import React from "react";
import RichText from "../rich-text";
import Model from "../../../../models/Model";
import Link from "../../../../models/prismic/link";
import PrismicLink from "../../../common/components/prismic-link";
import _isNil from "lodash.isnil";
import _get from "lodash.get";
import _noop from "lodash.noop";
import PrismicImg from "../prismic-img";
import Grid from "./grid";
import { ButtonLink } from "pws-design-system/design-system";
import { css } from "@emotion/react";

const gridItemVariants = {
  grid_item: item => <RichText markup={item.content} />,
  grid_image: slice => <img src={slice.url} />,
  grid: slice => <Grid slice={slice} />
};

const toNumber = str => {
  const num = Number(str);
  if (isNaN(num)) {
    return null;
  }
  return num;
};

const linkVariantMap = {
  plain: item => <PrismicLink link={item.link} />,
  primary: item => <PrismicLink link={item.link} Tag={ButtonLink} variant="solid" />,
  secondary: item => <PrismicLink link={item.link} Tag={ButtonLink} variant="outline" />
};

export class GridItemModel extends Model {
  link: Link = null;
  constructor(apiData) {
    super(apiData);
    if (
      _isNil(_get(apiData, "data.grid_link.url", null)) !== true ||
      _get(apiData, "data.grid_link.link_type") === "Document"
    ) {
      this.link = new Link({
        link: apiData.data.grid_link,
        link_text: apiData.data.grid_link_text,
        link_style: apiData.data.grid_link_style
      });
    }
  }
  get content() {
    return this.getData("grid_content.raw");
  }
  get imageFluid() {
    return this.getData("grid_image.localFile.childImageSharp.fluid");
  }
  get imageUrl() {
    return this.getData("grid_image.url");
  }
  get imageAlt() {
    return this.getData("grid_image.alt");
  }
  get imagePlacement() {
    return toNumber(this.getData("grid_image_placement"));
  }
  get gridItemStyle() {
    return this.getData("grid_item_style");
  }
  get startingColumnDesktop() {
    return toNumber(this.getData("starting_column_desktop"));
  }
  get endingColumnDesktop() {
    const endingColumn = toNumber(this.getData("ending_column_desktop"));
    if (isNaN(endingColumn)) return endingColumn;
    return endingColumn + 1;
  }
  get startingRowDesktop() {
    return toNumber(this.getData("starting_row_desktop"));
  }
  get endingRowDesktop() {
    return toNumber(this.getData("ending_row_desktop")) + 1;
  }
  get xAlignmentDesktop() {
    return this.getData("x_alignment_desktop");
  }
  get yAlignmentDesktop() {
    return this.getData("y_alignment_desktop");
  }
}

export const GridItem = ({ item }) => {
  const fontWeight = 600;
  const test = css``;
  const wrapperStyles = {
    default: {
      gridColumnStart: item.startingColumnDesktop,
      gridColumnEnd: item.endingColumnDesktop,
      gridRowStart: item.startingRowDesktop,
      gridRowEnd: item.endingRowDesktop,
      justifySelf: item.xAlignmentDesktop,
      alignSelf: item.yAlignmentDesktop,
      width: "100%"
    },
    callout: {
      background: "#f6faf7",
      borderTop: "4px solid #3bbb51",
      borderBottom: "4px solid #3bbb51",
      color: "#1d5a27",
      fontWeight: 600,
      fontSize: "16px",
      lineHeight: "24px",
      padding: "16px 16px"
    }
  };
  return (
    <div
      style={{
        ...wrapperStyles.default,
        ...(wrapperStyles[item.gridItemStyle] || {})
      }}
      css={
        item.gridItemStyle === "callout"
          ? css`
              p {
                margin-bottom: 0px;
                font-weight: 600;
                font-size: 16px;
              }
            `
          : ``
      }
    >
      {item.imageUrl && (
        <PrismicImg
          src={item.imageUrl}
          fluid={item.imageFluid}
          alt={item.imageAlt}
          mb={2}
          style={{ marginBottom: "32px" }}
        />
      )}
      {item.content && <RichText markup={item.content} />}
      {item.link &&
        _get(item, "link.isValid", false) &&
        _get(linkVariantMap, `${item.link.style}`, _noop)(item)}
    </div>
  );
};
