import _isNil from "lodash.isnil";
import React from "react";
import Grid from "./grid";

const sliceVariants = {
  grid: (slice: any) => <Grid slice={slice} />
};

const Slices = ({ slices }) => {
  return (
    <>
      {slices.map((slice: any) => {
        if (_isNil(slice)) {
          return slice;
        }
        return <>{sliceVariants[slice.slice_type](slice)}</>;
      })}
    </>
    // </div>
  );
};

export default Slices;
