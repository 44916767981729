import React, { useState } from "react";
import { Form, Box, Text } from "pws-design-system/design-system/";
import axios from "axios";
import { LoadingButton } from ".";
import State from "../../../common/components/state";
import _noop from "lodash.noop";
import _get from "lodash.get";
import { Machine } from "xstate";
import { useMachine } from "@xstate/react";
import { sanitizeErrorMessage } from "./sanitizeErrorMessage";

interface AuthenticationFormProps {
  HeadingSlot?: JSX.Element;
  SubmitSlot?: (isLoading: Boolean) => JSX.Element;
  FlashSlot?: JSX.Element;
  action: string;
  withCredentials: boolean;
  errorHandler: (...args: any[]) => void;
  successHandler: () => {};
  submissionHandler?: () => {};
  children: JSX.Element;
  disabled: Boolean;
}

const formMachine = Machine({
  id: "formMachine",
  initial: "start",
  states: {
    start: {
      on: {
        submitting: "submitting"
      }
    },
    submitting: {
      on: {
        errored: "errored",
        success: "success"
      }
    },
    success: {},
    errored: {
      on: {
        submitting: "submitting"
      }
    }
  }
});

const formDataToJson = formData => {
  var object = {};
  formData.forEach((value, key) => {
    object[key] = value;
  });
  var json = JSON.stringify(object);
  return json;
};

const handleSubmit = async (
  event,
  setErrors,
  send,
  action,
  withCredentials,
  successHandler,
  errorHandler
) => {
  event.preventDefault();
  const target = event.target as HTMLFormElement;
  const formData = new FormData(target);
  formData.append("submit", "");
  setErrors(null);
  send("submitting");
  try {
    const response = await axios({
      url: action,
      method: "POST",
      withCredentials,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
      },
      data: formData
    });
    const errors = response.data.errors || _get(response.data, "error.description");
    if (errors) {
      setErrors(sanitizeErrorMessage(errors));
      errorHandler("Form Submission Error", {
        error: errors,
        data: formDataToJson(formData),
        ...args
      });
      send("errored");
      return;
    } else {
      send("success");
      successHandler();
    }
  } catch (error) {
    send("errored");
    errorHandler("Form Submission Error", {
      error: error,
      data: formDataToJson(formData)
    });
    setErrors("There was a submission error.");
  }
};

export default function AuthenticationForm({
  HeadingSlot = null,
  SubmitSlot = (isLoading, disabled = false) => (
    <LoadingButton
      type="submit"
      width={["100%", null, null, "auto"]}
      isLoading={isLoading}
      disabled={disabled}
      mt={4}
    >
      Submit
    </LoadingButton>
  ),
  FlashSlot = null,
  submitHandler = handleSubmit,
  successHandler,
  errorHandler = _noop,
  action,
  withCredentials = true,
  children,
  disabled = false
}: AuthenticationFormProps) {
  const [errors, setErrors] = useState(null);
  const [currentState, send] = useMachine(formMachine);

  return (
    <>
      <Box mb={5}>
        {HeadingSlot}
        <State state={currentState} matches={["start"]}>
          {FlashSlot}
        </State>
        <State state={currentState} matches={["errored"]}>
          {errors !== null && (
            <div cy="global-form-error">
              <Box bg="red.100" p={2} mb={3} mx={-2} rounded="md">
                <Text
                  variant="caption1"
                  color="text.error"
                  dangerouslySetInnerHTML={{ __html: errors }}
                />
              </Box>
            </div>
          )}
        </State>
      </Box>
      <Form
        method="post"
        action={action}
        onSubmit={event =>
          submitHandler(
            event,
            setErrors,
            send,
            action,
            withCredentials,
            successHandler,
            errorHandler
          )
        }
      >
        {children}
        {SubmitSlot(
          currentState.matches("submitting") || currentState.matches("success"),
          disabled
        )}
      </Form>
    </>
  );
}
