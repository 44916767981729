import React from "react";
import Model from "../../../../models/Model";
import { GridItemModel, GridItem } from "./grid-item";
import { useMatchMediaQuery } from "../../../common/components/responsive-render/ResponsiveRender";

class GridModel extends Model {
  items: GridItemModel[];
  constructor(data: any) {
    super({ data });
    this.items = data.items.map((item: any) => {
      return new GridItemModel({ data: item });
    });
  }
}

const Grid = ({ slice }) => {
  const gridModel = new GridModel(slice);
  const mediaQueryMatches = useMatchMediaQuery();
  return (
    <div
      style={{
        display: mediaQueryMatches.includes("tablet-up") ? "grid" : "block",
        gridTemplateColumns: "repeat(12, 1fr)",
        gridTemplateRows: "max-content",
        gridAutoRows: "minmax(min-content, max-content)",
        gridGap: "32px"
      }}
    >
      {gridModel.items.map(item => (
        <GridItem item={item} />
      ))}
    </div>
  );
};

export default Grid;
