import sanitizeHtml from "sanitize-html";

const config = {
  allowedTags: ["a"],
  allowedAttributes: {
    a: ["href", "style"]
  },
  allowedSchemes: ["https"],
  transformTags: {
    a: sanitizeHtml.simpleTransform("a", { style: "color: black" })
  }
};

export const sanitizeErrorMessage = (htmlString: string) => sanitizeHtml(htmlString, config);
