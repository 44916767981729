import React from "react";
import { Heading, Text, Theme } from "pws-design-system/design-system";
import { List, ListItem } from "@chakra-ui/core";
import { RichText as PrismicRichText } from "prismic-reactjs";
import _get from "lodash.get";
import _isNil from "lodash.isnil";
import _cloneDeep from "lodash.clonedeep";
import PrismicLink from "../../../common/components/prismic-link/";
import LinkModel from "../../../../models/prismic/link/";
import { css } from "@emotion/react";

function htmlSerializerWithMarkup(markup, serializerConfig) {
  return function htmlSerializer(type, element, content, children, key) {
    const component = _get(serializerConfig, type, null);
    if (_isNil(component) === true) {
      return null;
    }
    if (type === "span") {
      return component(element.text.split("\n"));
    }
    if (type === "hyperlink") {
      const link = new LinkModel({
        link_text: content,
        link: {
          url: _get(element, "data.url", null),
          document: {
            uid: _get(element, "data.uid", null)
          },
          link_type: _get(element, "data.link_type", null)
        }
      });
      return component(element, link);
    }
    return component(children);
  };
}

function linkSerializer() {}

const defaultSerializerConfig = {
  oList: children => (
    <List styleType="decimal" my={4}>
      {children}
    </List>
  ),
  list: children => (
    <List styleType="disc" stylePos="outside" ml={"1.1em"} my={4}>
      {children}
    </List>
  ),
  listItem: children => (
    <ListItem>
      <Text as="span" mb={2} lineHeight="tall">
        {children}
      </Text>
    </ListItem>
  ),
  ["group-list-item"]: children => (
    <List styleType="disc" stylePos="outside" ml={"1.1em"} my={4}>
      {children}
    </List>
  ),
  ["o-list-item"]: children => (
    <ListItem>
      <Text as="span" mb={2} lineHeight="tall">
        {children}
      </Text>
    </ListItem>
  ),
  ["list-item"]: children => (
    <ListItem>
      <Text as="span" mb={2} lineHeight="tall">
        {children}
      </Text>
    </ListItem>
  ),
  paragraph: children => (
    <Text mb={4} lineHeight="tall">
      {children}
    </Text>
  ),
  heading3: children => (
    <Heading as="h3" size="xl">
      {children}
    </Heading>
  ),
  heading4: children => (
    <Heading as="h4" size="lg">
      {children}
    </Heading>
  ),
  heading5: children => (
    <Heading as="h5" size="md">
      {children}
    </Heading>
  ),
  heading6: children => (
    <Heading as="h6" size="sm">
      {children}
    </Heading>
  ),
  hyperlink: (children, link: LinkModel) => {
    return (
      <PrismicLink
        link={link}
        css={css`
          color: ${Theme.colors.brand.green.base};
          &:hover {
            color: ${Theme.colors.brand.green.base};
          }
          :after {
            border-bottom-color: ${Theme.colors.brand.green.base};
          }
        `}
      ></PrismicLink>
    );
  },
  preformatted: children => {
    return <pre style={{ whiteSpace: "unset" }}>{children}</pre>;
  },
  span: elements => {
    return (
      <>
        {elements.map(element => {
          if (element === "") {
            return <br />;
          } else {
            return <span>{element}</span>;
          }
        })}
      </>
    );
  }
};

interface IProps {
  markup: [];
  serializerConfig?: {};
}

export default ({ markup, serializerConfig }: IProps) => {
  const config = Object.assign({}, _cloneDeep(defaultSerializerConfig), serializerConfig);
  return PrismicRichText.render(markup, linkSerializer, htmlSerializerWithMarkup(markup, config));
};
