import React, { useState, useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";

import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box,
  Text,
  Link,
  Icon,
  FormField,
  Stack,
  Grid,
  Row,
  Col,
  Heading,
  Theme
} from "pws-design-system/design-system";
import { PageProps } from "../../pages/page/Page";
import PageModel from "../../../models/prismic/page";
import PageLayout from "../../common/layouts/PageLayout";
import RichText from "../../common/components/rich-text";
import { Form, LoadingButton } from "../authentication/common";
import UsePreviewDataStoreContainer from "../../common/hooks/stores/usePreviewDataStore";
import { messageStore } from "../../common/hooks/useMessages";
import Slices from "../../common/components/slices";
import Sentry from "../../../error";
import ErrorBoundary from "../../common/components/error-boundary";
import { formDataToJson } from "../../../utils/";
import { Providers } from "../../common/layouts/Layout";
import axios from "axios";

import _get from "lodash.get";
import { ThemeContainer } from "../../common/hooks/useTheme";
import { isNil } from "lodash";
const reportErrors = process.env.GATSBY_ERROR_REPORT_ENABLE;

const SupportPage = ({ pageContext }: PageProps): React.ReactElement => {
  const { theme } = ThemeContainer.useContainer();
  const recaptchaRef = React.createRef();

  const [isSuccess, setSuccess] = useState(false);
  const [recaptchaValue, setRecaptchaValue] = useState(null);

  let handle;

  const [previewData] = UsePreviewDataStoreContainer.useContainer();
  const data =
    previewData === null
      ? pageContext.data
      : { data: _get(previewData, "pageData.prismicPage.data") };
  const pageModel = new PageModel(data);

  const subjects = _get(messageStore, "form_subjects") || [];

  const handleSubmit = async (
    event,
    setErrors,
    send,
    action,
    withCredentials,
    successHandler,
    errorHandler
  ) => {
    event.preventDefault();
    const target = event.target as HTMLFormElement;
    const formData = new FormData(target);
    setErrors(null);
    send("submitting");
    const recaptchaValue = recaptchaRef.current.getValue();
    setRecaptchaValue(null);
    recaptchaRef.current?.reset();
    try {
      const apiResponse = await axios({
        url: action,
        method: "POST",
        withCredentials,
        headers: {
          "Content-Type": "application/json"
        },
        data: formDataToJson(formData)
      });
      send("success");
      successHandler();
    } catch (err) {
      const code = _get(err, "response.data.error.code", null);
      switch (code) {
        case "validation_error":
          setErrors(
            "We were unable to verify your email address. Please enter a valid email address or contact support directly at support@aerisweather.com."
          );
          break;
        case "invalid_email":
          setErrors(
            "We were unable to verify your email address. Please enter a valid email address or contact support directly at support@aerisweather.com."
          );
          break;
        case "invalid_captcha":
          setErrors(
            "Captcha verification failed. Refresh the page and try again or contact us directly at support@aerisweather.com."
          );
          break;
        default:
          setErrors(
            "Something went wrong. Please refresh the page and try again, or contact us directly at support@aerisweather.com."
          );
      }
      send("errored");
      handleError();
    }
  };

  const handleSuccess = () => {
    setSuccess(true);
  };

  const handleError = (error: string, errorInfo: { error: string | string[]; data: any }) => {
    if (reportErrors === "true") {
      Sentry.withScope(scope => {
        scope.setExtras(errorInfo);
        Sentry.captureException(error);
      });
    }
  };

  function onChange(value) {
    setRecaptchaValue(value);
  }

  return (
    <PageLayout
      page={pageModel}
      contentProps={{
        pb: [4, null, 6]
      }}
      metaTitle={pageModel.metaTitle}
      metaDescription={pageModel.metaDescription}
      headerProps={{ bg: theme.colors.bg.base.primary }}
      containerProps={{ bg: theme.colors.bg.base.primary }}
      contentProps={{ bg: theme.colors.bg.base.primary }}
    >
      <Grid>
        <Row>
          <Col sm={12} lg={6}>
            <Box mr={[0, null, 6]}>
              {pageModel.has("title") && (
                <Heading as="h1" variant="hero" mb={[4, null, 5]}>
                  {pageModel.title}
                </Heading>
              )}
              {pageModel.has("content") && <RichText markup={pageModel.content} />}
              {pageModel.has("slices") && <Slices slices={pageModel.slices} />}
            </Box>
          </Col>
          <Col sm={12} lg={5}>
            {isSuccess ? (
              <Alert
                mt={5}
                status="success"
                variant="subtle"
                flexDirection="column"
                justifyContent="center"
                textAlign="center"
                height="200px"
              >
                <AlertIcon size="40px" mr={0} />
                <AlertTitle mt={4} mb={1} fontSize="lg">
                  Contact inquiry submitted!
                </AlertTitle>
                <AlertDescription maxWidth="sm">
                  Thanks for reaching out. Our team will get back to you soon.
                </AlertDescription>
              </Alert>
            ) : (
              <Form
                mt={4}
                action={`${process.env.GATSBY_PWS_API_URL}${process.env.GATSBY_CONTACT_URL}`}
                successHandler={handleSuccess}
                errorHandler={handleError}
                submitHandler={handleSubmit}
                withCredentials={false}
                disabled={isNil(recaptchaValue)}
                SubmitSlot={(isLoading: Boolean, disabled) => (
                  <LoadingButton
                    width={["100%", null, null, "auto"]}
                    isLoading={isLoading}
                    disabled={disabled}
                    variantColor="brand"
                    type="submit"
                    mt={4}
                    mb={4}
                  >
                    Submit
                  </LoadingButton>
                )}
              >
                <Box mb={5}>
                  <Stack spacing={4}>
                    <Stack spacing={4} isInline>
                      <FormField
                        name="firstName"
                        label="First Name *"
                        field={{
                          type: "text",
                          variant: "flushed",
                          background: theme.colors.bg.base.primary
                        }}
                        required
                      />
                      <FormField
                        name="lastName"
                        label="Last Name *"
                        field={{
                          type: "text",
                          variant: "flushed",
                          background: theme.colors.bg.base.primary
                        }}
                        required
                      />
                    </Stack>
                    <FormField
                      name="email"
                      label="Email *"
                      field={{
                        type: "text",
                        variant: "flushed",
                        rightElement: <Icon name="email" color="gray.300" />,
                        background: theme.colors.bg.base.primary
                      }}
                      required
                    />
                    <FormField
                      type="select"
                      name="subject"
                      label="Subject *"
                      field={{
                        placeholder: "Select a topic...",
                        variant: "flushed",
                        isRequired: true,
                        required: true,
                        options: {
                          station_setup: "Uploading Data / Station Setup",
                          site_feedback: "Website Issues",
                          other: "Other"
                        }
                      }}
                    />
                    <FormField
                      type="textarea"
                      name="message"
                      label="Message *"
                      field={{
                        placeholder: "Provide your message or inquiry...",
                        variant: "flushed",
                        rows: 5,
                        height: "auto"
                      }}
                      required
                    />
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey={process.env.GATSBY_RECAPTCHA_V2_CLIENT_KEY}
                      onChange={onChange}
                    />
                  </Stack>
                </Box>
              </Form>
            )}
          </Col>
        </Row>
      </Grid>
    </PageLayout>
  );
};

const WrappedSupportPage = props => {
  return (
    <Providers>
      <SupportPage {...props} />
    </Providers>
  );
};

export default WrappedSupportPage;
