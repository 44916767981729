import React, { ReactNode } from "react";
import { State as XState } from "xstate";

interface StateProps {
  state: XState<any>;
  matches: string[];
  children?: ReactNode;
  render?: (rest: any) => React.ReactElement;
}

export default function State({
  state,
  matches = [],
  children,
  render,
  ...rest
}: StateProps): React.ReactElement | null {
  const matched = matches.map(match => state.matches(match));

  if (matched.some(match => match === true)) {
    if (typeof render === "function") {
      return render(rest);
    }
    return <>{children}</>;
  }
  return null;
}
