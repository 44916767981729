import React from "react";
import { Heading, Stack, Spinner, Button } from "pws-design-system/design-system/";
import AuthenticationForm from "./AuthenticationForm";
import { ButtonProps } from "pws-design-system/design-system/components/button/Button";

interface FormHeadingProps {
  children?: JSX.Element;
}
function FormHeading({ children }: FormHeadingProps) {
  return (
    <Heading as="h1" mb={5} variant="headline">
      {children}
    </Heading>
  );
}

interface FormProps {
  action: string;
  children?: JSX.Element | JSX.Element[];
  [k: string]: any;
}
export function Form({ children, ...rest }: FormProps) {
  return (
    <AuthenticationForm {...rest}>
      <Stack spacing={4}>{children}</Stack>
    </AuthenticationForm>
  );
}

interface LoadingButtonProps extends ButtonProps {
  isLoading: Boolean;
  children: string | JSX.Element;
  [k: string]: any;
}
export function LoadingButton({ isLoading, disabled, children, ...rest }: LoadingButtonProps) {
  return (
    // @ts-ignore disabled prop
    <Button disabled={isLoading || disabled} {...rest}>
      {children}
      {isLoading && <Spinner color="white" ml={3} size="sm"></Spinner>}
    </Button>
  );
}
