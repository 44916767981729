import Model from "../../Model";
import _isNil from "lodash.isnil";
import _get from "lodash.get";

class Slice extends Model {
  get type() {
    return this.getData("slice_type");
  }
}

class GridSlice {}

const sliceFactory = (sliceData: SliceTypes) => {
  const sliceType = _get(sliceData, "slice_type", "");
  if (sliceType === "grid_item") {
    return new GridRichTextSlice({ data: sliceData });
  }
  if (sliceType === "grid_image") {
    return new GridImageSlice({ data: sliceData });
  }
  return sliceData;
};

export default class Page extends Model {
  // _slices: Slice[] = null;
  // constructor(apiData: object, type = "Page") {
  //   super(apiData);
  //   if (_isNil(_get(apiData, "data.body", null)) === false) {
  //     const slices = _get(apiData, "data.body", []);
  //     this._slices = slices.map((sliceData: SliceTypes) => sliceData);
  //   }
  // }
  get title(): string | null {
    return this.getData("title");
  }
  get metaTitle(): string {
    return this.getData(`meta_title`);
  }
  get metaDescription(): string {
    return this.getData(`meta_description`);
  }
  get content(): string {
    return this.getData(`content.raw`);
  }
  get slices() {
    return this.getData("body");
  }
}
